<template>
	<div>
		<div class="ft20 cl-main ftw600">补单审核</div>

		<div class="mt20">
			<div class="pd20 bg-w " style="min-height: 800px;">
				<div>
					<a-radio-group v-model="search.time_type" button-style="solid" @change="changeTimeType">
						<a-radio-button :value="2">
							未审核
						</a-radio-button>
						<a-radio-button :value="1">
							已审核
						</a-radio-button>
            <a-radio-button :value="3">
              未过审
            </a-radio-button>
					</a-radio-group>
				</div>
				<div class="mt20">
					<div class="wxb-table-gray">
						<a-table rowKey="rank" :columns="columns" :pagination="pagination" :rowKey="(record, index) => { return index }"  @change="handleTableChange"
							:data-source="datas" :loading="loading">
							
							<div slot="servicetiem" slot-scope="servicetiem,record">
                <div class="ml5" style="text-align: left;">
                  <div>开始：{{record.start_forma}}</div>
                  <div class="mt2">结束：{{record.end_forma}}</div>
                </div>
							</div>
							
							<div class="flex alcenter"  slot="serviceman_mobile" slot-scope="record">
                <img v-if="record.serviceman.face!=null" :src="record.serviceman.face" class="member-index-face" />
                <img v-else src="../../assets/image/woyaoyou_head_defult@2x.png" class="member-index-face" />
                <div class="ml5" style="text-align: left;">
                  <div>{{record.serviceman.name}}</div>
                  <div class="mt2">{{record.serviceman.mobile}}</div>
                </div>
							</div>
							
							<template slot="action" slot-scope="record,index">
								<div class="flex center" v-if="record.status !=1" >
									<a-dropdown placement="bottomRight">
										<span class="more-act">
											<i class="iconfont iconmore_gray"></i>
										</span>
										<a-menu slot="overlay">
											<a-menu-item>
												<a class="menu-act" href="javascript:;" @click="getDetail(record)">
													<i class="iconfont ft14 iconsee"></i>
													<span class="ml10">审核</span>
												</a>
											</a-menu-item>
											
										</a-menu>
									</a-dropdown>
								</div>
							</template>
						</a-table>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="suppVisible">
			<supp-detail :visible="suppVisible" :service_id="service_id" @cancel="cancelShowEmployeePerformanceDetail" @ok="okEditType"></supp-detail>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import suppDetail from './components/supp/modal/SuppDetail.vue';
	export default {
		mixins: [listMixin],
		components:{
      suppDetail,
		},
		data() {
			return {
				loading: false,
        suppVisible:false,
				total_commission:0,
        service_id:0,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					staff:'',
					time_type:2,
				},
				columns: [
          {title: '编号',dataIndex: 'id',width:100, align: 'center'},
          {title: '异常原因',dataIndex: 'type',align: 'left',ellipsis: true},
					{title: '服务时间',dataIndex: 'servicetiem',align: 'left',scopedSlots: {customRender: 'servicetiem'}},
					{title: '服务人员',key: 'serviceman_mobile',align: 'left',scopedSlots: {customRender: 'serviceman_mobile'}},
          {title: '申请备注',dataIndex: 'notes',align: 'left',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods: {
			getLists() {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getAppointmentSupp',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
          status:this.search.time_type,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.total_commission=res.total_commission;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			getDetail(record){
        this.service_id = record.id;
				this.suppVisible=true;
			},
			cancelShowEmployeePerformanceDetail(){
				this.suppVisible=false;
			},

      okEditType(){
        this.suppVisible=false;
        this.getLists();
      },
			
			changeTimeType(){
				this.pagination.current=1;
				this.getLists();
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
.member-index-face {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
  border: 2px solid #FFFFFF;
}
</style>
