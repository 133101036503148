<template>
	<div>
		<a-modal title="补单申请" :width="800" :visible="visible"  @cancel="handleCancel">
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
          确认审批
        </a-button>
      </template>
      <template>
        <a-descriptions title="异常工单信息" :column="2">
          <a-descriptions-item label="异常原因">{{datas.type}}</a-descriptions-item>
          <a-descriptions-item label="服务项目">{{appointment.project_name}}</a-descriptions-item>
          <a-descriptions-item label="服务人员"> {{appointment.serviceman_name}} ({{appointment.serviceman_mobile}})</a-descriptions-item>
          <a-descriptions-item label="服务对象"> {{appointment.contact_name}} ({{appointment.contact_mobile}})</a-descriptions-item>
          <a-descriptions-item label="预约时间">{{appointment.day}} {{appointment.start_time}}~{{appointment.end_time}}</a-descriptions-item>
          <a-descriptions-item label="服务地址">{{appointment.contact_address}}</a-descriptions-item>
        </a-descriptions>

        <a-descriptions :column="2" title="补单申请信息" columns="2">
          <a-descriptions-item label="开始时间">{{datas.start_forma}}</a-descriptions-item>
          <a-descriptions-item label="结束时间" style="width: 400px;"> {{datas.end_forma}}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="1">
          <a-descriptions-item label="申请备注"> {{datas.notes}}</a-descriptions-item>
          <a-descriptions-item label="服务凭证">
            <img v-if="item != null" :src="item" class="member-detail-face mr10"  v-for="(item,index) in datas.pictures"/>
          </a-descriptions-item>
          <a-descriptions-item label="审批意见">
            <a-radio-group  v-model="form.status">
              <a-radio :value="3">不同意</a-radio>
              <a-radio :value="1">同意</a-radio>
            </a-radio-group>
          </a-descriptions-item>
          <a-descriptions-item label="审批备注">
            <a-input v-model="form.status_notes" :maxLength="30" placeholder="输入备注信息" style="width: 480px;"></a-input>
          </a-descriptions-item>
        </a-descriptions>

      </template>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
      service_id:{
				type:Number,
				default:0,
			}

		},
		data() {
			return {
				loading: false,
        confirmLoading: false,
				search:{
					month:'',
				},
				columns: [
					{title: 'ID',dataIndex: 'appointment_id',align: 'center',ellipsis: true},
					{title: '用户手机',dataIndex: 'member_mobile',align: 'center'},
					{title: '预约业绩',dataIndex: 'need_pay',align: 'center'},
					{title: '额外增值',dataIndex: 'added_price',align: 'center'},
				],
				total_num:0,
				total_commission:0,
				datas: [],
        appointment:[],
        form: {
          status:2,
          status_notes:'',
        }
			}
		},
		created() {
      this.loaddata();
		},
		methods: {

      loaddata(){
        if(this.loading==true) return;
        this.loading=true;
        this.$http.api('admin/getAppointmentSuppDetail',{
          service_id:this.service_id
        }).then(res=>{
          this.datas = res;
          this.appointment = res.appointment;
          this.loading=false;
        }).catch(res=>{
          this.loading=false;
          this.$router.go(-1);
        });

      },

      /**
       * 提交数据
       */
      onSubmit() {
        if (this.form.status_notes == '') {
          this.$message.error('请填写审批备注');
          return;
        }

        if(this.form.status == 2){
          this.$message.error('请选择是否同意审批');
          return;
        }
        if(this.confirmLoading==true) return;
        this.confirmLoading=true;
        this.$http.api('admin/getAppointmentSuppSave', {
          service_id:this.service_id,
          status: this.form.status,
          status_notes: this.form.status_notes,
        }).then(res => {
          this.$message.success('保存成功',1,()=>{
            this.confirmLoading=false;
            this.$emit("ok");
          })
        }).catch(res => {
          this.confirmLoading=false;
        })
      },
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
		}
	}
</script>

<style>
  .member-detail-face{width:100px;height: auto;float: left;}
</style>
